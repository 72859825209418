import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Checkbox, Descriptions, Divider, Pagination, Select, Spin } from 'antd';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import { searchPatent, SearchPatentInput, SearchPatentRes } from '../../api';
import Search from '../../components/Search';
import styles from './index.module.less';

const SearchPage = () => {
  const query = useSearchParam('query');
  const reactHistory = useHistory();
  if (!query) {
    reactHistory.push('/');
  }

  const { values, setFieldValue, submitForm } = useFormik<SearchPatentInput>({
    initialValues: { query_string: query! },
    onSubmit: (values) => {
      if (values.apply_date?.start) {
        values.apply_date.end = values.apply_date?.start.replace('01-01', '12-31');
      } else {
        values.apply_date = undefined;
      }
      if (values.open_date?.start) {
        values.open_date.end = values.open_date?.start.replace('01-01', '12-31');
      } else {
        values.open_date = undefined;
      }
      search(values);
    },
  });

  const {
    mutate: search,
    data: list,
    isLoading: isSearchLoading,
  } = useMutation('searchPatent', (value: SearchPatentInput) => searchPatent(value));

  function onSearch(reloadPage?: boolean) {
    if (reloadPage) {
      setFieldValue('page_number', 1);
    }
    submitForm();
  }

  useEffect(() => {
    search({ query_string: query! });
    window.scrollTo({ top: 0 });
  }, [query, search]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Search
          className={styles.search}
          onSearch={submitForm}
          value={values.query_string || ''}
          setValue={(value) => setFieldValue('query_string', value)}
        />
        <div className={styles.result}>
          <div className={classNames(styles.filter, styles.card)}>
            <div className={styles.label}>专利状态</div>
            <Checkbox.Group
              style={{
                display: 'flex',
                gap: '10px',
                flexDirection: 'column',
              }}
              value={values.patent_status ? [values.patent_status] : undefined}
              onChange={(e) => {
                setFieldValue('patent_status', e[e.length - 1]);
                onSearch(true);
              }}>
              <div>
                <Checkbox value={'在审'}>在审</Checkbox>
              </div>
              <div>
                <Checkbox value={'有效'}>有效</Checkbox>
              </div>
            </Checkbox.Group>
            <Divider />
            <div className={styles.label}>发明类型</div>
            <Checkbox.Group
              style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}
              value={values.patent_type ? [values.patent_type] : undefined}
              onChange={(e) => {
                setFieldValue('patent_type', e[e.length - 1]);
                onSearch(true);
              }}>
              <div>
                <Checkbox value={'发明'}>发明</Checkbox>
              </div>
              <div>
                <Checkbox value={'实用新型'}>实用新型</Checkbox>
              </div>
              <div>
                <Checkbox value={'外观设计'}>外观设计</Checkbox>
              </div>
            </Checkbox.Group>
            <Divider />
            <div className={styles.label}>申请日期</div>
            <Checkbox.Group
              style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}
              value={values.apply_date?.start ? [values.apply_date?.start] : undefined}
              onChange={(e) => {
                setFieldValue('apply_date.start', e[e.length - 1]);
                onSearch(true);
              }}>
              {_renderDate()}
            </Checkbox.Group>
            <Divider />
            <div className={styles.label}>公开日期</div>
            <Checkbox.Group
              style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}
              value={values.open_date?.start ? [values.open_date?.start] : undefined}
              onChange={(e) => {
                setFieldValue('open_date.start', e[e.length - 1]);
                onSearch(true);
              }}>
              {_renderDate()}
            </Checkbox.Group>
            <Divider />
          </div>
          <div className={classNames(styles.doc, styles.card)}>
            <div className={styles.docHeader}>
              <div className={styles.title}>搜索结果</div>
              <div>
                <Select defaultValue={'时间默认排序'} style={{ marginRight: '20px' }} />
                共搜索到
                <span className={styles.count}>{list?.data.pagination?.total}</span>
                条相关专利
              </div>
            </div>
            <Divider />
            {isSearchLoading ? (
              <Spin />
            ) : (
              <>
                {list?.data.data?.map((it) => (
                  <Item item={it} key={it.name} />
                ))}
                <Pagination
                  showSizeChanger={false}
                  current={list?.data.pagination?.page}
                  pageSize={list?.data.pagination?.page_size || 0}
                  total={list?.data.pagination?.total}
                  onChange={(page) => {
                    setFieldValue('page_number', page);
                    onSearch();
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function _renderDate() {
    return [
      '21',
      '20',
      '19',
      '18',
      '17',
      '16',
      '15',
      '14',
      '13',
      '12',
      '11',
      '10',
      '09',
    ].map((it) => (
      <div key={it}>
        <Checkbox value={`20${it}-01-01`}>20{it}</Checkbox>
      </div>
    ));
  }
};

const Item = ({ item }: { item: SearchPatentRes }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={styles.item}>
      <div className={styles.name}>{item.name}</div>
      <Descriptions column={4}>
        <Descriptions.Item label={'公开号'}>{item.open_id || '-'}</Descriptions.Item>
        <Descriptions.Item label={'申请号'}>{item.apply_id || '-'}</Descriptions.Item>
        <Descriptions.Item label={'发明人'}>{item.inventor || '-'}</Descriptions.Item>
        <Descriptions.Item label={'申请日期'}>{item.apply_date || '-'}</Descriptions.Item>
        <Descriptions.Item label={'公开日期'}>{item.open_date || '-'}</Descriptions.Item>
        <Descriptions.Item label={'专利状态'}>
          {item.patent_status || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={'专利类型'}>
          {item.patent_type || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={'IPC'}>{item.ipc || '-'}</Descriptions.Item>
        <Descriptions.Item
          label={'发明简介'}
          contentStyle={
            !expanded
              ? {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }
              : undefined
          }>
          {item.abstract}
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.toggleExpand} onClick={() => setExpanded((v) => !v)}>
        {expanded ? (
          <div className={styles.collapse}>
            收起详情 <UpOutlined />
          </div>
        ) : (
          <div>
            展开详情 <DownOutlined />
          </div>
        )}
      </div>
    </div>
  );
};
export default SearchPage;
