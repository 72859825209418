import { Layout, Spin } from 'antd';
import { Suspense } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import Home from '../../pages/Home';
import RadarPage from '../../pages/RadarPage';
import SearchPage from '../../pages/SearchPage';
import styles from './index.module.less';

const App = () => {
  const reactHistory = useHistory();

  function onJumpToDataV() {
    window.open(
      'http://datav.aliyuncs.com/share/413e35212031852354f23c16257f59ac',
      '_blank',
    );
  }

  return (
    <Layout className={styles.layout}>
      {_renderHeader()}
      {_renderContent()}
    </Layout>
  );

  function _renderHeader() {
    return (
      <Layout.Header className={styles.contentHeader}>
        <div className={styles.headerTitle}>南京大学智慧专利分析平台</div>
        <div className={styles.menus}>
          <div className={styles.menu} onClick={() => reactHistory.push('/')}>
            首页
          </div>
          <div className={styles.menu} onClick={() => reactHistory.push('/radar')}>
            行业雷达
          </div>
          <div className={styles.menu} onClick={onJumpToDataV}>
            专利大屏
          </div>
          <div className={styles.menu}>专家挖掘</div>
          <div className={styles.menu}>智能咨询</div>
        </div>
      </Layout.Header>
    );
  }

  function _renderContent() {
    return (
      <Layout.Content className={styles.content}>
        <Suspense fallback={<Spin />}>
          <Switch>
            <Route path={'/search'}>
              <SearchPage />
            </Route>
            <Route path={'/radar'}>
              <RadarPage />
            </Route>
            <Route path={'/'}>
              <Home />
            </Route>
          </Switch>
        </Suspense>
      </Layout.Content>
    );
  }
};

export default App;
