import { Divider, Input } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { getIndustries } from '../../api';
import styles from './index.module.less';

const RadarPage = () => {
  const [curIndustry, setIndustry] = useState<{ Title: string; Url: string }>();
  const listQuery = useQuery('getIndustries', () => getIndustries(), {
    onSuccess: (data) => setIndustry(data.data[0]),
  });
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div className={styles.label}>行业分类</div>
        <Input.Search placeholder={'请输入行业名称'} />
        <Divider />
        <div className={styles.list}>
          {listQuery.data?.data?.map((it) => (
            <div
              key={it.Title}
              className={classNames(
                styles.item,
                it.Title === curIndustry?.Title ? styles.active : undefined,
              )}
              onClick={() => setIndustry(it)}>
              {it.Title}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.content}>
        {curIndustry?.Url && (
          <iframe src={curIndustry.Url} frameBorder={0} width={'100%'} height={'100%'} />
        )}
      </div>
    </div>
  );
};

export default RadarPage;
