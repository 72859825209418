import { Descriptions, Image } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import BannerImage from '../../assets/banner.png';
import ImageCarousel1 from '../../assets/carousel1.png';
import ImageCarousel2 from '../../assets/carousel2.png';
import ImageCarousel3 from '../../assets/carousel3.png';
import ImageIcon1 from '../../assets/title-icon1.png';
import ImageIcon2 from '../../assets/title-icon2.png';
import Search from '../../components/Search';
import { formatDate } from '../../utils';
import styles from './index.module.less';

const inventorKeywords = [
  '平野嵩明',
  '宫泽信二',
  '前田兼作',
  '守屋雄介',
  '古瀬骏介',
  '大冈豊',
  '周振义',
  '张旭磊',
  '刘志成',
  '蔡晓岚',
  '史明亮',
];
const topicKeywords = [
  '悟道模型',
  '知识图谱推理',
  '多模态情感分析',
  'AI 智能',
  '芯片',
  '新能源',
  'VR',
];

const articles = [
  '半导体芯片及其散热总成',
  '知识图谱向量表示方法、知识图谱关系推理方法及系统',
  '一种基于知识图谱路径分析的知识推理方法及系统',
  '一种油浸式电力变压器压力释放阀故障录波装置及方法',
  '一种双内腔太赫兹波参量振荡器',
  '一种4-氟-6-三氟甲基-3-吡啶甲酸酯中间体及其合成方法和应用',
  '数据评价方法及装置、存储介质及电子设备',
  '聚乙烯醇系聚合物膜及其制造方法',
  '采用可编程解码时间窗滤波的激光扫描码符号读取系统',
  '人胰岛淀粉样多肽(HIAPP)特异性抗体及其用途',
];
const articles2 = [
  '含锂复合氧化物以及其制造方法',
  '低剖面光学警告系统',
  '含有PTC材料的二次电池电极的制造方法和由此方法制造的电极',
  '二环式吡啶化合物',
  '用于动态编配的系统和方法',
  '用于事件触发型传送的无线网络中的抢先资源分配',
  '翻转装置和液晶显示面板的制造方法',
  '发现向量内的重复值的多个实例的方法和装置及到排序的应用',
  '具有电池SOC估计器的智能车辆重新刷新',
  '修复信息存储电路以及包括其的半导体装置',
];

const Home = () => {
  const reactHistory = useHistory();
  const [value, setValue] = useState('');

  const [lineChartRef, setLineChartRef] = useState<HTMLDivElement | null>(null);
  const [radarChartRef, setRadarChartRef] = useState<HTMLDivElement | null>(null);

  function searchKeyword(v?: string) {
    reactHistory.push('/search?query=' + (v || value));
  }

  useEffect(() => {
    if (lineChartRef) {
      const lineChart = echarts.init(lineChartRef);
      lineChart.setOption({
        title: {
          text: '专利发明趋势',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['申请', '公开'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '申请',
            type: 'line',
            data: [38, 56, 163, 2111, 6854, 6265, 3564, 7254, 50809].map((it) => it * 10),
          },
          {
            name: '公开',
            type: 'line',
            data: [3645, 8979, 18829, 29722, 75533, 31304, 20440, 89236, 57743].map(
              (it) => it * 10,
            ),
          },
        ],
      });
    }
  }, [lineChartRef]);

  useEffect(() => {
    if (radarChartRef) {
      const radarChart = echarts.init(radarChartRef);
      radarChart.setOption({
        title: {
          text: '专利发明类型分布',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: '5%',
          left: 'center',
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 570645, name: '外观设计' },
              { value: 3031656, name: '发明' },
            ],
          },
        ],
      });
    }
  }, [radarChartRef]);

  return (
    <div className={styles.container}>
      <Image
        src={BannerImage}
        height={480}
        style={{ objectFit: 'cover' }}
        preview={false}
      />
      <div className={styles.content}>
        <Search
          className={styles.search}
          value={value}
          setValue={setValue}
          onSearch={() => searchKeyword()}
        />
        <Descriptions column={1} className={styles.keywords}>
          <Descriptions.Item label={'发明人热搜'}>
            <div className={styles.tags}>
              {inventorKeywords.map((it) => (
                <div className={styles.tag} key={it} onClick={() => searchKeyword(it)}>
                  {it}
                </div>
              ))}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={'话题热搜'}>
            <div className={styles.tags}>
              {topicKeywords.map((it) => (
                <div className={styles.tag} key={it} onClick={() => searchKeyword(it)}>
                  {it}
                </div>
              ))}
            </div>
          </Descriptions.Item>
        </Descriptions>
        <div className={styles.charts}>
          <div className={styles.card}>
            <div className={styles.lineChart} ref={setLineChartRef} />
          </div>
          <div className={styles.card}>
            <div className={styles.lineChart} ref={setRadarChartRef} />
          </div>
        </div>
        <div className={styles.carousels}>
          <div className={styles.carousel}>
            <Image preview={false} src={ImageCarousel1} width={390} height={230} />
            <div className={styles.text}>AI 人工智能，神经网络</div>
          </div>
          <div className={styles.carousel}>
            <Image preview={false} src={ImageCarousel2} width={390} height={230} />
            <div className={styles.text}>5G芯片，发明专利专题</div>
          </div>
          <div className={styles.carousel}>
            <Image preview={false} src={ImageCarousel3} width={390} height={230} />
            <div className={styles.text}>智慧城市 智慧交通</div>
          </div>
        </div>
        <div className={styles.tables}>
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <Image src={ImageIcon1} height={15} />
              影响力排行榜
            </div>
            {articles.map((it, index) => (
              <div key={it} className={styles.tableRow}>
                <div className={styles.tableCell}>
                  <div
                    className={classNames(
                      styles.number,
                      index >= 3 ? styles.numberFade : undefined,
                    )}>
                    No.{index + 1}
                  </div>
                  <div className={styles.title}>{it}</div>
                </div>
                <div className={styles.tableCell2}>
                  <div className={styles.date}>
                    {formatDate(dayjs().subtract(Math.floor(Math.random() * 100), 'day'))}
                  </div>
                  <div className={styles.impact}>
                    影响力：{(100 - index * Math.random() * 5).toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <Image src={ImageIcon2} height={16} />
              技术前瞻榜
            </div>
            {articles2.map((it, index) => (
              <div key={it} className={styles.tableRow}>
                <div className={styles.tableCell}>
                  <div
                    className={classNames(
                      styles.number,
                      index >= 3 ? styles.numberFade : undefined,
                    )}>
                    No.{index + 1}
                  </div>
                  <div className={styles.title}>{it}</div>
                </div>
                <div className={styles.tableCell2}>
                  <div className={styles.date}>
                    {formatDate(dayjs().subtract(Math.floor(Math.random() * 100), 'day'))}
                  </div>
                  <div className={styles.impact}>
                    前瞻性：{(100 - index * Math.random() * 5).toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
