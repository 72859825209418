import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
  baseURL: 'http://159.75.84.47:3700/patent/v1',
});

const nocoInstance = axios.create({
  baseURL: 'https://noco.techower.com/nc/patent_v1_dw3t/api/v1',
  headers: {
    'xc-auth':
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Indhbmd5aUB0ZWNob3dlci5jb20iLCJmaXJzdG5hbWUiOm51bGwsImxhc3RuYW1lIjpudWxsLCJpZCI6MSwicm9sZXMiOiJ1c2VyIiwiaWF0IjoxNjQ1Njc0Nzg0fQ.SQJRPjsXPaLfEBz9reWpjl7jC2muPLUqV3Oy9nW5GkA',
  },
});

export interface SearchPatentInput {
  abstract?: string;
  agency?: string;
  agent?: string;
  applicants?: string;
  apply_date?: {
    end?: string;
    start?: string;
  };
  expiry_date?: {
    end?: string;
    start?: string;
  };
  inventor?: string;
  ipc?: string[];
  name?: string;
  open_date?: {
    end?: string;
    start?: string;
  };
  page_number?: number;
  page_size?: number;
  patent_status?: string;
  patent_type?: string;
  patentee?: string;
  query_string?: string;
}

export interface Pagination {
  has_next: boolean;
  has_prev: boolean;
  next_num: number;
  page: number;
  page_size: number;
  pages: number;
  prev_num: number;
  total: number;
}

export interface SearchPatentRes {
  abstract?: string;
  abstract_en?: string;
  adcode?: string;
  address?: string;
  agency?: string;
  agency_en?: string;
  agent?: string;
  agent_en?: string;
  applicants?: string;
  applicants_en?: string;
  apply_date?: string;
  apply_id?: string;
  examiner?: string;
  expiry_date?: string;
  img1?: string;
  img2?: string;
  inventor?: string;
  inventor_en?: string;
  inventor_ids?: string[];
  ipc?: string[];
  name?: string;
  name_en?: string;
  open_date?: string;
  open_id?: string;
  patent_status?: string;
  patent_type?: string;
  patentee?: string;
  patentee_en?: string;
}

export interface Response<T> {
  code: number;
  message: string;
  pagination?: Pagination;
  status: string;
  data?: T;
}

export function searchPatent(input: SearchPatentInput) {
  return instance.post<SearchPatentInput, AxiosResponse<Response<SearchPatentRes[]>>>(
    '/patent_list',
    input,
  );
}

export function getIndustries() {
  return nocoInstance.get<null, AxiosResponse<{ Title: string; Url: string }[]>>(
    '/kanban',
  );
}
