import { Image, Input } from 'antd';
import classNames from 'classnames';

import SearchImage from '../../assets/search-btn.png';
import styles from './index.module.less';

const Search = ({
  onSearch,
  className,
  value,
  setValue,
}: {
  className?: string;
  value: string;
  setValue: (value: string) => void;
  onSearch: () => void;
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.inputContainer}>
        <div className={styles.sourceSelect}>国内专利库</div>
        <Input
          className={styles.search}
          bordered={false}
          placeholder={'支持搜索关键词、公司名、人名、专利号等'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className={styles.searchButton} onClick={onSearch}>
          <Image src={SearchImage} width={96} height={48} preview={false} />
        </div>
      </div>
      {/*<div className={styles.advanceSearch}>高级搜索</div>*/}
    </div>
  );
};
export default Search;
